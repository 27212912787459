/** @jsxImportSource @emotion/react */
import React, { useLayoutEffect, useState } from 'react';
import { useAuth, useLoginWithRedirect, useAuthActions } from '@frontegg/react';
import { clarityInitialize, pendoInitialize } from '../external-tools';
import { setCurrentTenant, TTenantDetails, getTenant, getCurrentTenant } from '../routes/ManageTenants/tenants';
import { setFronteggJWTCookie, logout } from '../utils/fronteggAuth';
import { PSLoadingScreen } from '../ui-kit';

type IProps = {
    children: React.ReactNode;
};

const RootAuthWrapper: React.FC<IProps> = (props) => {
    const { children } = props;
    const { isAuthenticated, isLoading: isUserLoginLoading, user } = useAuth();
    const loginWithRedirect = useLoginWithRedirect();
    const [loggedIn, setLoggedIn] = useState<string | null>(localStorage.getItem("loggedIn"));

    useLayoutEffect((): void => {
        (async () => {
            if (!user || isUserLoginLoading || !isAuthenticated) return;
            setFronteggJWTCookie(user);


            const psCookieTenant = getCurrentTenant();
            const userTenant: TTenantDetails | null | undefined = await getTenant(user);
            if (!userTenant) {
                logout();
                return;
            }

            if(psCookieTenant?.id !== userTenant.id) {
                //await switchTenant({ tenantId: trueUserTenant.id, callback: () => setCurrentTenant(trueUserTenant) }); return;
                setCurrentTenant(userTenant);
            }


            if (loggedIn === null) {
                await fetch('/api/access/register-login');
                localStorage.setItem("loggedIn", "");
                setLoggedIn("");
            }

            if (process.env.NODE_ENV !== 'development') {
                pendoInitialize(user);
                clarityInitialize();
            }

        })();
    }, [user, isAuthenticated, isUserLoginLoading]);

    if (isUserLoginLoading) {

        return <PSLoadingScreen />;
    }

    if (!isAuthenticated) {
        loginWithRedirect()
        return <PSLoadingScreen />;
    }

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    )
}

export default RootAuthWrapper;